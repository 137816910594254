.light {
  color: white;
}

.heart {
  color: #e80c7a;
}

.footer-text {
  font-size: medium;
}

.footer-bar {
  float:none;
  margin:0 auto;
  display: block;
}

.level-1-button {
  margin-bottom:20px;
}

.separator-button-group {
  margin-right:10px;
}

.linebreak {
  margin-top:10px;
}

.tag {
  margin-left:10px;
}

.bottom-of-page {
  margin-bottom:50px;
}
